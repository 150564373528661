<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        Participant Management
        <v-chip v-if="this.organization" class="ma-2" close @click:close="clearOrganization()">
          {{ this.organization.name }}
        </v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Participants')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="filterByEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ParticipantTypesField
                  dense
                  v-model="filterByParticipantTypes"
                  label="Participant Types"
                  @input="onSubmit"
                ></ParticipantTypesField>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="mr-2" small fab v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        mobile-breakpoint="800"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.organization="{ item }">
          <span v-if="item.organization">
            {{ item.organization.name }}
          </span>
        </template>
        <template v-slot:item.user.lastSuccessfulLoginDate="{ item }">
          {{ item.user.lastSuccessfulLoginDate | formatDateFromNow }}
        </template>
        <template v-slot:item.missingRequiredInformation="{ item }">
          <v-icon v-if="!item.missingRequiredInformation" color="green">mdi-check</v-icon>
          <v-icon v-else color="red">mdi-close</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-container v-if="!isSelectedProgramEndUser">
            <v-tooltip bottom v-if="cantImpersonateReason(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" fab small color="warning" v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-switch-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ cantImpersonateReason(item) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" fab small @click="onImpersonate(item)" v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-switch-outline</v-icon>
                </v-btn>
              </template>
              <span>Impersonate</span>
            </v-tooltip>
          </v-container>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ParticipantTypesField from "../../gapp-components/components/fields/ParticipantTypesField.vue";
import { mapGetters } from "vuex";

export default {
  components: { ParticipantTypesField },
  name: "CprParticipantManagement",
  metaInfo: {
    title: "Participant Management"
  },
  data() {
    return {
      search: "",
      filterByParticipantTypes: [],

      loading: false,
      items: [],
      nameFilter: "",
      headers: [
        {
          value: "participantKey",
          text: "Participant #",
          sortable: true
        },
        {
          value: "user.firstName",
          text: "First Name",
          sortable: true
        },
        {
          value: "user.lastName",
          text: "Last Name",
          sortable: true
        },
        {
          value: "user.username",
          text: "Username",
          sortable: false
        },
        {
          value: "organization",
          text: "Organization",
          sortable: true
        },
        {
          value: "participantType.name",
          text: "Participant Type",
          align: "end",
          sortable: false
        },
        {
          value: "status.description",
          text: "Participant Status",
          align: "end",
          sortable: true
        },
        {
          value: "user.lastSuccessfulLoginDate",
          text: "Last Successful Login",
          align: "end",
          sortable: true
        },
        {
          value: "missingRequiredInformation",
          text: "Profile Complete?",
          align: "center",
          sortable: false
        },
        {
          value: "actions",
          text: "Actions",
          align: "center",
          sortable: false
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10
      },
      organization: null,
      filterByEffective: "Active",
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.fetchData();
    },
    onSubmit() {
      this.options.page = 0;
      this.fetchData();
    },
    onClearSearch() {
      this.search = "";
      this.filterByEffective = undefined;
      this.filterByParticipantTypes = [];
      this.onSubmit();
    },
    clearOrganization() {
      this.$router.push({ name: "cprCompanyManagement" });
    },
    fetchData() {
      this.loading = true;
      if (this.$route.query.organizationId) {
        if (this.organization) {
          return this.fetchParticipants(this.organization).finally(() => {
            this.loading = false;
          });
        } else {
          return this.$api.get("/api/organizations/" + this.$route.query.organizationId).then(({ data }) => {
            this.organization = data;
            return this.fetchParticipants(this.organization).finally(() => {
              this.loading = false;
            });
          });
        }
      } else {
        return this.fetchParticipants().finally(() => {
          this.loading = false;
        });
      }
    },
    fetchParticipants(org) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      filters.isEffective = this.filterByEffective;

      if (this.filterByParticipantTypes && this.filterByParticipantTypes.length > 0) {
        filters.participantTypes = this.filterByParticipantTypes.map(participantType => {
          return {
            id: participantType.id
          };
        });
      }
      if (org) {
        filters.organization = { id: org.id };
      }
      let sortByName = "sort";
      if (sortBy && sortBy.length > 0 && sortBy[0].includes(".")) {
        sortByName = "nestedSort";
      }

      return this.$api
        .post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&" +
                sortByName +
                "=" +
                sortBy[0] +
                "," +
                ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        });
    },
    cantImpersonateReason(participant) {
      if (this.selectedParticipant && this.selectedParticipant.id == participant.id) {
        return "Can't impersonate yourself";
      }
      if (this.impersonationParticipant && this.impersonationParticipant.id == participant.id) {
        return "Already impersonating user";
      }
      if (!this.$privilege.hasImpersonatePrivilege()) {
        return "Don't have impersonating privilege";
      }
      if (this.$privilege.hasImpersonatePrivilegeForUser(participant.user)) {
        return "Can't impersonate one who has impersonate privilege";
      }
      // Allowed to impersonate
      return undefined;
    },
    onImpersonate(participant) {
      this.isBusy = true;
      this.$api.get("/api/participants/byParticipantId/" + participant.id).then(({ data }) => {
        this.$auth.startImpersonation(data).then(() => {
          this.isBusy = false;
          this.$router.push({ name: "dashboard" }).catch(() => {});
        });
      });
    }
  },
  mounted() {
    if (this.$route.query.active != undefined) {
      this.filterByEffective = parseInt(this.$route.query.active) == 1;
    }

    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Participant Management" }
    ]);
  },
  computed: {
    ...mapGetters(["impersonationParticipant", "selectedParticipant", "selectedProgram"]),
    isSelectedProgramEndUser() {
      return process.env.VUE_APP_PROGRAM_EUR_KEY == this.selectedProgram.programKey;
    },
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        this.filterByEffective != undefined ||
        (this.filterByParticipantTypes && this.filterByParticipantTypes.length > 0);
      return check;
    }
  }
};
</script>
