<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="participantTypes"
    item-value="id"
    :item-text="renderItem"
    clearable
    multiple
    return-object
  >
    <template v-slot:selection="data">
      {{ (showKey ? data.item.participantTypeKey + " - " : "") + data.item.name }} <br />
    </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ParticipantTypesField",
  props: {
    value: Array,
    status: { type: String, required: false },
    showKey: { type: Boolean, default: false }
  },
  data: () => ({
    participantTypes: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    renderItem(item) {
      return (this.showKey ? item.participantTypeKey + " - " : "") + item.name;
    }
  },
  mounted() {
    let filters = {};
    filters.isEffective = this.status;
    let sort = "name";
    if (this.showKey) {
      sort = "participantTypeKey";
    }

    ApiService.post("/api/participantTypes/search?sort=" + sort + "&size=100", filters).then(({ data }) => {
      this.participantTypes = data.content;
    });
  }
};
</script>
